import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "using-roles-with-netlify-identity"
    }}>{`Using roles with Netlify Identity`}</h1>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` comes with built-in support for authorization.`}</p>
    <p>{`Netlify Identity requires no additional configuration to support roles. Head over to `}<a parentName="p" {...{
        "href": "https://netlify.com"
      }}>{`netlify.com`}</a>{` and navigate to the Identity portion of your site's configuration.`}</p>
    <h2 {...{
      "id": "add-roles-to-users"
    }}>{`Add roles to users`}</h2>
    <p>{`Find a user that needs a role and click Edit Settings. Then type-in your roles.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/0f882/netlify-identity-roles.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "39.46188340807175%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'157\\'%20viewBox=\\'0%200%20400%20157\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M163%2097c0%208%200%2010%202%2010a8467%208467%200%2000117-1H164V88l-1%209M5%20131v9l1%208h33v-17l-17-1-17%201m50%207c-1%203%201%204%206%204s6%200%206-2h1c0%201%201%202%207%202s6%200%206-3l-1-3-1%201h-2l-2%201h-1c0-2-11-1-13%201-3%203-4%202-4%200s0-2%202-1l2-1c-1-2-5-1-6%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/913a0bfc2cb3a58b9cb535bf4f44dd74/ca0a1/netlify-identity-roles.webp 223w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/75680/netlify-identity-roles.webp 445w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/8d1ba/netlify-identity-roles.webp 890w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/3838e/netlify-identity-roles.webp 1335w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/8754d/netlify-identity-roles.webp 1584w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/913a0bfc2cb3a58b9cb535bf4f44dd74/e92b6/netlify-identity-roles.png 223w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/e66bf/netlify-identity-roles.png 445w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/4ef49/netlify-identity-roles.png 890w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/4e814/netlify-identity-roles.png 1335w", "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/0f882/netlify-identity-roles.png 1584w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/913a0bfc2cb3a58b9cb535bf4f44dd74/4ef49/netlify-identity-roles.png",
                "alt": "Add roles to user",
                "title": "Add roles to user",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Add roles to user`}</figcaption>{`
  `}</figure></p>
    <p>{`Make sure you save :)`}</p>
    <h2 {...{
      "id": "isauthorized"
    }}>{`isAuthorized`}</h2>
    <p>{`You can now use `}<a parentName="p" {...{
        "href": "/docs/roles"
      }}>{`isAuthorized`}</a>{` to verify user authorization.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      